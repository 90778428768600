import React from 'react';
import './app.scss';
import Main from "./components/main/main";
import Identify from "./components/identify/identify";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProceedToSign from "./components/proceedToSign/proceedToSign";
import PostSignature from "./components/postSignature/postSignature";

function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={<Main/>}
                />
                <Route
                    path="/sign"
                    element={<ProceedToSign />}
                />
                <Route
                    path="/post-signature"
                    element={<PostSignature />}
                />
                <Route
                    path="/t/:token"
                    element={<Identify />}
                />
            </Routes>
        </BrowserRouter>

    </div>
  );
}

export default App;
