import React, {useEffect, useState} from 'react';
import nextIcon from '../../images/next.png';
import './identify.scss';
import {useParams, useNavigate} from "react-router-dom";
import {getPensionData} from "../../api";
import Header from "../header/header";
import {Mixpanel} from "../../mixpanel";

const Identify = () => {
    Mixpanel.track( 'Page view',{page: 'identify'});
    const navigate = useNavigate();
    const [idNumber, setIdNumber] = useState("");
    const [apiError, setApiError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    let {token} = useParams();

    const onChange = (e: any) => {
        let value = e.target.value;
        if (value.length > 9)
            value = value.substring(0, 9);

        setIdNumber(value);
    }

    useEffect(() => {
        const idNumber = localStorage.getItem("idNumber");
        if (idNumber)
            setIdNumber(idNumber);
    }, [])

    const submit = async () => {
        if (isLoading)
            return;

        setApiError(false);
        setIsLoading(true);
        try {
            const result = await getPensionData(idNumber, token || '')
            localStorage.setItem("idNumber", idNumber);
            Mixpanel.track( 'submit ID clicked',{success: true});
            navigate("/", {state: result});
        } catch (err) {
            console.log(err);
            setApiError(true);
            Mixpanel.track( 'submit ID clicked',{success: false});
        } finally {
            setIsLoading(false);
        }
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            submit();
        }
    };

    return (
        <div className="identify">
            <div className="wrapper">
                <Header/>
                <div className="content">

                    <div className="hi-user">היי!</div>
                    <div className="texts">
                        <div className="text2">

                            <b>סיכום השיחה הפנסיונית מחכה לך כאן.</b><br/>
                            אנא הזינו את תעודת הזהות
                        </div>
                    </div>

                    <div className="input">
                        {apiError && <div className="error">
                            <b>מספר הזהות לא מתאים</b>
                        </div>}
                        <input
                            className={apiError ? 'error' : ''}
                            type="tel"
                            max={999999999}
                            placeholder="תעודת זהות"
                            value={idNumber}
                            onChange={onChange}
                            onKeyDown={handleKeyDown}
                        />
                        <span className="button-wrapper" onClick={submit}>
                            <img src={nextIcon}/>
                        </span>
                    </div>
                </div>
            </div>
            {/*<div className="buttons">*/}
            {/*    <button className="approve-button" onClick={submit}>המשך</button>*/}
            {/*</div>*/}
        </div>
    );
}

export default Identify;
