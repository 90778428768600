import React, {useEffect, useRef, useState} from 'react';
import {isMobile} from 'react-device-detect';
import downArrow from '../../images/down-arrow.png';
import upArrow from '../../images/up-arrow.png';
import checkmark from '../../images/checkmark.png';
import info from '../../images/info.png';
import './main.scss';
import Popup from "../popup/popup";
import {useLocation, useNavigate} from "react-router-dom";
import {getPensionData} from "../../api";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import ProductInfoPopup from './productInfoPopup';
import {addCommas, getCompanyName, getProductType} from "../utils";
import Header from "../header/header";
import {Helmet} from "react-helmet";
import {Mixpanel} from "../../mixpanel";

const Main = () => {
    Mixpanel.track( 'Page view',{page: 'main'});
    const navigate = useNavigate();
    const [showNotInterestedPopup, setShowNotInterestedPopup] = useState(false);
    const [savingAmount, setSavingAmount] = useState(0);
    const [popupTitle, setPopupTitle] = useState('');
    const [popupText, setPopupText] = useState('');
    const [selectedProduct, setSelectedProduct] = useState<any>();

    const [data, setData] = useState<any>({});
    const [showSavingDetails, setShowSavingDetails] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [height, setHeight] = useState(0);

    const wrapperRef = useRef<HTMLDivElement>(null)
    const buttonsRef = useRef<HTMLDivElement>(null)

    const {state} = useLocation();

    const loadData = async (idNumber: string, token: string) => {
        try {
            const result = await getPensionData(idNumber, token || '');
            Mixpanel.identify(idNumber);
            Mixpanel.people.set({
                first_name: result.data.clientName,
                gender: result.data.gender,
                idNumber: idNumber,
                numOfProducts: state?.products.length,
            });

            setData(result.data);
        } catch (err) {
            console.log(err);
            window.localStorage.removeItem("idNumber");
            navigate("/t/" + token);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        console.log("state change", state)
        if (state) {
            setData(state);
        } else {
            const idNumber = window.localStorage.getItem("idNumber");
            const token = window.localStorage.getItem("token");
            if (idNumber && token) {
                loadData(idNumber, token);
                return;
            } else if (token) {
                navigate("/t/" + token);
                return;
            } else {
                navigate("/t/a");
                return;
            }
        }

        setSavingAmount(state.products.reduce((total: number, p: any) => total + p.potentialSavings, 0));
    }, state);



    const proceedToSign = () => {
        Mixpanel.track( 'proceed to sign clicked',{success: true});
        localStorage.setItem("gender", state.gender)
        navigate("/sign",{state});
    }

    const notInterestedClicked = () => {
        setShowNotInterestedPopup(true);
    }


    const getProductText = (product: any) => {
        if (product.newProduct.action === 'agentAssignment') {
            // return `העברת ${getProductType(product.oldProduct)} ל${getProductType(product.newProduct)} בחברת ${getCompanyName(product.newProduct)}`
            return `מינוי Better כסוכן מטפל ב${getProductType(product.oldProduct)} ב${getCompanyName(product.newProduct)}`;
        }        
        if (product.newProduct.productType !== product.oldProduct.productType) {
            return `העברת ${getProductType(product.oldProduct)} ל${getProductType(product.newProduct)} בחברת ${getCompanyName(product.newProduct)}`
        }
        if (product.newProduct.companyName !== product.oldProduct.companyName) {
            return `העברת ${getProductType(product.newProduct)} לחברת ${getCompanyName(product.newProduct)}`
        }
        return `פתיחת ${getProductType(product.newProduct)} ב${getCompanyName(product.newProduct)}`
    }

    useEffect(()=>{
        window.addEventListener('resize', ()=>{
            if (buttonsRef?.current?.clientHeight) {
                setHeight(window.innerHeight - buttonsRef.current.clientHeight);
            }
        });
    },[])

    useEffect(() => {
        if (buttonsRef?.current?.clientHeight) {
            setHeight(window.innerHeight - buttonsRef.current.clientHeight);
        }
    })




    return (
        <>
            <Helmet>
                <meta property="og:title" content='סיכום השיחה הפנסיונית' />
                <meta property="og:url" content={`https://client.allbetter.co.il/t/${window.localStorage.getItem("token")}`} />
                <title>סיכום השיחה הפנסיונית</title>
                <meta property="og:description" content={`חיסכון מצטבר עבור ${data?.clientName}`} />
                <meta name="description" content={`חיסכון מצטבר עבור ${data?.clientName}`} />
            </Helmet>
            {showNotInterestedPopup &&
                <Popup
                    onClose={() => setShowNotInterestedPopup(false)}
                    button2Text={data?.gender === 'female' ? 'מאשרת' : 'מאשר'}
                    button1Text="כן רוצה לחסוך!"
                >
                    <div className="title">ויתור על הצעה לחיסכון</div>
                    <div className="content">
                        <div className="text1">
                            <p>{data?.clientName} האם אתה בטוח?</p>
                            <p>                            אנו בכל מקרה נמשיך עם איתור חסכונות בעולמות הפיננסים הבאים 💪
                                במידה ודעתך תשתנה, תמיד ניתן לעדכן אותנו בוואסטאפ
                            </p>
                        </div>
                    </div>
                </Popup>}

            {selectedProduct ?
                <ProductInfoPopup
                    gender={state.gender}
                    product={selectedProduct}
                    onClose={()=>setSelectedProduct(undefined)} />  : null}

            <div className="main">
                <div className="wrapper" ref={wrapperRef} style={showSavingDetails?{height:'auto'}:{height: height+'px',overflowY:'auto'}}>
                    <Header />
                    <div className="content">
                        <div className="hi-user">היי {data?.clientName}!</div>
                        <div className="texts">
                            <div className="text1">
                                בהמשך לשיחתנו, איתרנו לך חיסכון משמעותי בפנסיה, קופות גמל וקרנות השתלמות!                                
                            </div>
                            <div className="text2">
                                {data?.gender === 'female' ? 'לחצי' : 'לחץ'} על הכפתור מטה כדי לאשר את ביצוע הפעולה ולחתום על הטפסים הנדרשים
                            </div>
                            <div className="text3">
                                פירוט הפעולות שנבצע עבורך נמצא כאן {" "}
                                <span className="click-here" onClick={() => setShowSavingDetails(!showSavingDetails)}>
                                    <u>כאן.</u><img src={showSavingDetails ? upArrow : downArrow} alt="logo"/>
                                </span>
                            </div>
                        </div>
                        <SlideDown className={'slide'}>
                            {showSavingDetails && <div>
                                <div className="saving-details">                                    
                                    {state?.products.map((product: any) =>
                                        <>
                                            <img className="checkmark" src={checkmark}/>
                                            <span className="text">{getProductText(product)}</span>
                                            <img className="info" src={info} onClick={() => setSelectedProduct(product)}/>
                                        </>
                                    )}                                 
                                </div>                                
                            </div>}
                        </SlideDown>

                    </div>
                </div>
                <div className="buttons" ref={buttonsRef}>
                    <button className="approve-button" onClick={proceedToSign}>רוצה להתקדם</button>
                    <div className="not-interested">
                        <u onClick={notInterestedClicked}>לא {data?.gender === 'female' ? 'מעוניינת' : 'מעוניין'}{" "}לחסוך</u>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Main;
