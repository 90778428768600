import React from 'react';
import xIcon from '../../images/x.png';

import './popup.scss';
import {Mixpanel} from "../../mixpanel";


interface PopupProps {
    onClose: () => void;
    children?: string | JSX.Element | JSX.Element[],
    button1Text?:string,
    button2Text?:string,
}

const Popup = ({ onClose, children, button1Text, button2Text }: PopupProps)=>{
    const closePopup = () => {
        Mixpanel.track( 'Popup close',{button1Text, button2Text});
        onClose();
    }

    const button1Clicked = () => {
        Mixpanel.track( 'Popup button 1 clicked',{button1Text, button2Text});
        onClose();
    }

    const button2Clicked = () => {
        Mixpanel.track( 'Popup button 2 clicked',{button1Text, button2Text});
        onClose();
    }

    return (
        <div className="full-screen">
            <div className="wrapper">
                <img className="close" src={xIcon} onClick={closePopup}/>
                {children}

                <div className="buttons">
                    <button className="approve-button" onClick={button1Clicked}>{button1Text || 'אוקיי'}</button>
                    {button2Text?<span className="gap"></span>:null }
                    {button2Text? <button className="approve-button second" onClick={button2Clicked}>{button2Text}</button>:null}
                </div>
            </div>
        </div>
    );
}

export default Popup;
