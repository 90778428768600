import React, {useEffect, useRef, useState} from 'react';
import './proceedToSign.scss';
import {useLocation, useNavigate} from "react-router-dom";
import 'react-slidedown/lib/slidedown.css'
import Header from '../header/header';
import {Mixpanel} from "../../mixpanel";


const ProceedToSign = () => {
    Mixpanel.track( 'Page view',{page: 'proceed-to-sign'});
    const navigate = useNavigate();
    const {state} = useLocation();
    const [height, setHeight] = useState(0);

    const buttonsRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (!state) {
            navigate(-1);
        }
    }, state);

    const redirectToSign = () => {
        Mixpanel.track( 'Sign documents clicked');
        window.location.href = state.signingLink;
    }

    useEffect(()=>{
        window.addEventListener('resize', ()=>{
            if (buttonsRef?.current?.clientHeight) {
                setHeight(window.innerHeight - buttonsRef.current.clientHeight);
            }
        });
    },[])

    useEffect(() => {
        if (buttonsRef?.current?.clientHeight) {
            setHeight(window.innerHeight - buttonsRef.current.clientHeight);
        }
    })

    return (
        <>
            <div className="proceedToSign">
                <div className="wrapper"style={{height: height+'px'}}>
                    <Header />
                    <div className="content">

                        <div className="hi-user">{state.clientName},</div>
                        <div className="texts">
                            <div className="text1">
                                אנא {state.gender === 'female'?'חתמי':'חתום'} על המסמכים מטה כדי שנוכל לבצע עבורך את החיסכון.
                            </div>
                            <div className="text2">
                                לאחר אישורך, המערכת שלנו תבצע בקרה 24/7 לאיתור הנחות פוטנציאליות נוספות. כמו כן, אנו נמונה כסוכן הפנסיוני שלך, ונעניק לך שירות שוטף מול המעסיק הנוכחי ובכל שאלה או פעולה אותה {state.gender === 'female'?'תרצי':'תרצה'} לבצע.
                            </div>
                            <div className="text2">
                                {state.gender === 'female'?'שימי':'שים'} לב כי יידרשו ממך {state.numberOfSignatures}{" "}
                                חתימות בסה״כ בכדי שנוכל לבצע את הפעולה. ככה הם אוהבים בתעשייה הפיננסית. 😊
                            </div>
                        </div>
                    </div>
                </div>
                <div className="buttons" ref={buttonsRef}>
                    <button className="approve-button" onClick={redirectToSign}>לחתימה על הטפסים</button>
                </div>
            </div>
        </>
    );
}

export default ProceedToSign;
