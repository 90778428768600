
import mixpanel from 'mixpanel-browser';
mixpanel.init('d38e247173f37ce1ae041f7e2f9975ea', {debug: true});

const envCheck = true;//process.env.NODE_ENV === 'production';

const actions = {
    identify: (id) => {
        if (envCheck)
            mixpanel.identify(id);
    },
    alias: (id) => {
        if (envCheck)
            mixpanel.alias(id);
    },
    track: (name, props) => {
        if (envCheck)
            mixpanel.track(name, props);
    },
    people: {
        set: (props) => {
            if (envCheck)
                mixpanel.people.set(props);
        },
    },
    getId: ()=> mixpanel.get_distinct_id()
};

export let Mixpanel = actions;