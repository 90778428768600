import logo from "../../images/better-logo.png";
import phoneIcon from "../../images/whatsapp.png";
import React from "react";
import './header.scss';

const Header = () => {
    return (

        <header className="header">
            <img src={logo} className="logo" alt="logo"/>

            <a
                className="phone-link"
                href="https://wa.me/97233763766"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={phoneIcon} alt="phone-call"/>
            </a>
        </header>
    )
}

export default Header;