import React, { useEffect, useState } from 'react';
import './postSignature.scss';
import { useLocation, useNavigate } from "react-router-dom";
import 'react-slidedown/lib/slidedown.css'
import Header from '../header/header';


import copyIcon from "../../images/copy-icon.png"
import { Mixpanel } from "../../mixpanel";

const PostSignature = () => {
    Mixpanel.track('Page view', { page: 'post-signature' });
    const onClick = () => {
        window.navigator.clipboard.writeText('+97235703080');
    }
    const [gender, setGender] = useState('')
    useEffect(() => {
        setGender(localStorage.getItem("gender") || '')
    })

    return (
        <>
            <div className="proceedToSign">
                <div className="wrapper">
                    <Header />
                    <div className="content">

                        <div className="hi-user">כל הכבוד! מכאן זה עלינו.</div>
                        <div className="texts">
                            <div className="text1">
                                נעדכן אותך כאשר החיסכון יושלם בהצלחה.
                            </div>
                            <div className="text2">
                                
                            </div>
                            <div className="phone-number" onClick={onClick}>
                                <img src={copyIcon} />
                                <span className="title">Better 03-5703080</span>
                            </div>
                            <div className="text2">
                                <p>כל הכבוד! מכאן הכל עלינו. נעדכן אותך כאשר החיסכון יושלם בהצלחה.</p>
                                <p>חשוב להדגיש, אם מצלצלים מחברת הביטוח כדי לשכנע אותך להתחרט, נבקש לא לחתום על כלום. אנחנו רוצים לבחון אם הם מציעים אלטרנטיבה בעלת ערך אמיתי, ולכן יש לדרוש מהם את ההצעה <b>כתובה</b> ולהעביר אלינו בווטסאפ. אנחנו נבחן מיידית את ההצעה ונעדכן אותך מה משתלם ביותר עבורך.</p>
                                <p>מרגע זה אנחנו מלווים אותך בכל ההיבטים הקשורים לפנסיה שלך. על מנת שנוכל לעשות את המיטב עבורך בכל רגע נתון, יש לעדכן אותנו בווטסאפ על כל שינוי מהותי בחייך (מעבר מקום עבודה, הולדת ילד, שינוי מצב משפחתי וכו׳).</p>
                            </div>
                        </div>

                        <div className="footer">
                            בהצלחה!<br />
                            צוות Better
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default PostSignature;