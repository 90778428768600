const getProductType = (product: any): string => {
    switch (product.productType) {
        case 'comprehensivePensionFund':
            return 'קרן פנסיה מקיפה';
        case 'complementaryPensionFund':
            return 'קרן פנסיה משלימה'
        case 'directorsInsurance':
            return 'ביטוח מנהלים'
        case 'studyFund':
            return 'קרן השתלמות';
        case 'providentFund':
            return 'קופת גמל';
        case 'investmentFund':
            return 'קופת גמל להשקעה'
        default:
            return ''
    }
}


const getCompanyName = (product: any): string => {
    const words = [
        'קרנות',
        'גמל ופנסיה',
        'פנסיה וגמל',
        'קופות גמל',
        'חברה לבטוח',
        'חברה לביטוח',
        'פנסיה',
        'גמל',
        'ניהול',
        'בע"מ'
    ]

    for (const word of words)
        if (product.companyName.includes(word))
            return product.companyName.split(word)[0].trim();

    return product.companyName;

}


const addCommas = (num: number | undefined) => {
    if (num == undefined)
        return '';

    return Math.round(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export {
    addCommas,
    getCompanyName,
    getProductType
}


