import Popup from "../popup/popup";
import React from "react";
import { addCommas, getCompanyName, getProductType } from "../utils";

interface ProductInfoPopupProps {
    onClose: () => void,
    product: any,
    gender: string
}


const ProductInfoPopup = ({ onClose, product, gender }: ProductInfoPopupProps) => {    
    const isSameProduct = product.newProduct.productType === product.oldProduct.productType && product.newProduct.companyName === product.oldProduct.companyName;

    const isTransfer = product.newProduct.action === 'transfer' || (!product.newProduct.action && !isSameProduct);
    const isNewProduct = product.newProduct.action === 'newProduct' || (!product.newProduct.action && isSameProduct);
    const isAgentAssignment = product.newProduct.action === 'agentAssignment';

    const getTitle = () => {
        if (isAgentAssignment) {
            return 'מינוי Better כסוכן מטפל';
        } else if (isNewProduct) {
            return 'פתיחת קרן חדשה';
        } else {        
            return "הפחתת דמי ניהול"
        }
    }
    return (
        <>
            <Popup onClose={onClose}>
                <div className="title">{getTitle()}</div>
                <div className="content">
                    <div className="text1">
                        {isTransfer && (
                            <p>
                                כיום {gender === 'female' ? 'את משלמת' : 'אתה משלם'}{" "}
                                ב{getProductType(product.oldProduct)} בחברת {getCompanyName(product.oldProduct)}{" "}
                                {product.oldProduct.commissions.deposit ? <>
                                    דמי ניהול בשיעור של {product.oldProduct.commissions.deposit}% מכל הפקדה ועוד {" "}
                                </> : null}
                                {product.oldProduct.commissions.savings}% מהיתרה הצבורה בקרן בכל שנה.
                            </p>
                        )}
                        {(isTransfer || isNewProduct) && 
                            <p>
                                בזכות כוח הקנייה שלנו, הצלחנו להגיע עבורך
                                לדמי ניהול {isNewProduct ? "מצויינים של" : "משופרים באופן משמעותי"} - {" "}
                                {product.newProduct.commissions.deposit ? <>
                                    {product.newProduct.commissions.deposit}% מכל הפקדה ו-
                                </> : null}
                                {product.newProduct.commissions.savings}% בלבד מהיתרה הצבורה
                                בחברת {getCompanyName(product.newProduct)}.
                            </p>
                        }
                        

                        {isAgentAssignment && <p>
                        אנו נעניק לך שירות ובקרה עבור מוצר פנסיוני זה, תוכל לפנות אלינו בכל עת כדי להתייעץ ולבצע שינויים כרצונך.
                        </p>}
                    </div>

                </div>
            </Popup>
        </>
    )
}

export default ProductInfoPopup;