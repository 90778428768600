import axios from "axios";

let API_URL="";
if (window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1')  || window.location.hostname.startsWith('192.168')) {
    API_URL = `http://${window.location.hostname}:4010/api`;
} else if (window.location.hostname.includes('staging')) {
    API_URL = 'https://staging-api.allbetter.co.il:4010/api';
} else {
    API_URL = 'https://p.allbetter.co.il/api';
}


const getPensionData = async (idNumber: string, token: string)=>{
    const url = `${API_URL}/client-dashboard/pension/data?token=${token}&idNumber=${idNumber}`;
    const result = await axios.get(url);
    return result.data;
}

export {
    getPensionData
}